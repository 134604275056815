@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@700&family=Source+Code+Pro:wght@700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Antonio:wght@400;700&family=Source+Code+Pro:wght@700&display=swap');

$primary: #306c3f !important;
$secondary: #f8b12c !important;
$almost-white: #F8F8F8 !important;
$line-border-grey: #D0D0D0 !important;
$light-grey: #E8E8E8 !important;
$disabled-grey: #A8A8A8 !important;

$white: #FFFFFF !important;
$grey: #D4D4D4 !important;
$dark-grey: #88888B !important;
$success: #6FA202 !important;
$error: #CE1226 !important;
$warning: #f2af00 !important;
$info: #41b6e6 !important;

$thin-bold: 100 !important;
$extra-light-bold: 200 !important;
$light-bold: 300 !important;
$medium-bold: 500 !important;
$semi-bold: 600 !important;
$bold: 700 !important;

$default-font: "Poppins";
$default-box-shadow: 0px 0px 5px $grey;
$default-text-color: #000000;
$default-border: 1px solid $line-border-grey;
$default-border-radius: 5px;

// remove up and down arrow
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

// #root {
//     width: 100vw;
//     height: 100vh;
//     overflow-x: hidden;
//     background-color: #F2F2F2;
// }

*,
body {
    font-family: $default-font !important;
    box-sizing: border-box;
}

.numbers {
    font-family: 'Source Code Pro', monospace !important;
}

.antonioFont {
    font-family: 'Antonio', sans-serif !important;
}

p {
    font-size: 14px;
    margin: 0px;
    color: $default-text-color;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.flex-justify-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flex-justify-end {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.flex-justify-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-column-start {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.alert-container {
    position: fixed;
    top: -100px;
    opacity: 0;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: $default-border-radius;
    box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
    min-width: 320px;
    max-width: 380px;
    z-index: 9999;
    transition: all ease-in-out 0.3s;

    &.show-alert {
        top: 30px;
        opacity: 1;
    }

    &.hide-alert {
        top: -100px;
        opacity: 0;
    }
}

.login {
    width: 100%;
    height: 100%;
    position: relative;

    .login-container {
        max-width: 450px;
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;

        img {
            height: 150px;
            margin-bottom: 20px;
        }

        .login-form {
            width: 100%;
            padding: 30px;
            background-color: $white;
            border-radius: $default-border-radius;
            box-shadow: 0px 0px 10px $grey;
        }
    }
}

.text-header {
    font-size: 24px;
    font-weight: 600;
    color: $primary;
}

.text-subheader {
    font-size: 18px;
    font-weight: 600;
    color: $primary;
}

//Body
.body-container {
    max-width: 1280px;
    margin: 41px auto 0px;
    padding: 20px;
}

//Sidebar
.pro-sidebar.collapsed {
    width: inherit !important;
    min-width: unset !important;
}

.header {
    border-bottom: $default-border;
    transition: all ease-in-out 0.3s;

    .dropdown {
        button {
            background-color: transparent !important;
            color: $default-text-color !important;
            border: 0;
        }
    }
}

.pro-sidebar {
    width: 100% !important;
    min-width: 200px !important;

    .pro-sidebar-inner {
        background-color: $primary;
    }
}

.pro-sidebar .pro-menu {
    ul {
        padding: 0px !important;

        .pro-sub-menu {
            .pro-inner-list-item {
                position: relative;
                background-color: #194063 !important;
            }

            .pro-menu-item {
                padding: 5px 0px 5px 64px;
            }

            .pro-menu-item:hover {
                background-color: rgba(255, 255, 255, 0.2);
            }
        }
    }
}

.widthCollapse {
    width: "8.66% !important";
}

.pro-sidebar.collapsed .pro-menu ul .pro-menu-item.pro-sub-menu .pro-inner-list-item li.pro-menu-item {
    width: 100% !important;
}

.react-slidedown.pro-inner-list-item.transitioning,
.collapsed .react-slidedown.pro-inner-list-item {
    transition-property: height;
    background-color: #172741 !important;
}


.sideBarWidth {
    width: 100%;
    min-width: 100%;
}

.sideBarFix {
    position: fixed !important;
    min-width: unset !important;
    width: 16.7% !important;
}

.widthCollapsed {
    width: 6% !important;

    li.pro-menu-item {
        width: 80px !important;
    }

    .siteIcon {
        padding: 15px 10px !important;

        img {
            margin-right: 0px !important;
        }
    }

    .pro-menu-item.pro-sub-menu {
        position: relative;
        // width: 100% !important;
    }
}

.widthFull {
    width: 94% !important;

    .headerFixed {
        position: fixed;
        right: 0;
        width: inherit;
        z-index: 999;
    }
}

.normalPanel {
    width: 83% !important;
    display: block;
}

.headerFixed {
    width: inherit;
    position: fixed;
    right: 0;
    z-index: 999;
}

.fullPanel {
    width: 100% !important;
    display: block;
    // float: right;
}

#hidethis {
    visibility: hidden;
}

#pagePanel {
    -webkit-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -ms-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

span.pro-item-content {
    font-size: 14px;
}

button.btn.btn-secondary {
    color: $almost-white !important;
    background-color: transparent;
    border-color: transparent;
}

select#voucherType {
    text-transform: capitalize;
}

.removeTr {
    display: none;
}

.pro-sidebar .pro-menu .pro-menu-item {
    .pro-inner-item {
        .pro-icon-wrapper {
            margin-right: 25px !important;
            background-color: #21212145 !important;
        }
    }
}

.pro-sidebar.collapsed .pro-menu {
    ul {
        .pro-menu-item.pro-sub-menu {
            .pro-inner-list-item {
                // left: 80px;
                display: none;
            }
        }
    }
}

.btnActions {
    .col-12 {
        button {
            width: 70% !important;
        }
    }
}


.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    padding-left: unset !important;
}

#pagePannel {
    margin-top: 41px;
}

.payment-instructions-table {
    width: 100%;
    background-color: $almost-white;

    td {
        border: $default-border;
        padding: 6px 10px;
    }

    .payment-instructions-table-total {
        background-color: $white;
        border: none !important;
        padding: 3px 0px;
        text-align: right;
    }
}

.cta_part {
    position: absolute;
    top: 39%;
    left: 55%;
}


.not_selected {
    background: #fff !important;
    color: #222 !important;
}

.not_selected_side {
    background: #fff !important;
}

.selected_number {
    background: #fff !important;
    color: #222 !important;
}

.selected_number_l {
    background: #f8b12c !important;
    color: #fff;
}

.css-1y4v7ra-MuiCardContent-root:last-child {
    padding-bottom: 0;
}

.MuiCard-root {
    border-radius: "12px !important";
}

.active_tabs {
    position: relative;
}

.active_tabs ::before {
    content: "";
    position: absolute;
    left: 22%;
    bottom: -2px;
    height: 15px;
    width: 60%;
    border-bottom: 3px solid;
    border-radius: "10px";
}

div#playerDiv {
    width: 100% !important;
}

@media (min-width: 1600px) {

    // .pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item {
    //     padding-left: 64px !important;
    // }
    .widthFull {
        width: 96% !important;

        .headerFixed {
            width: inherit;
            position: fixed;
            right: 0;
            z-index: 999;
        }
    }

    .widthCollapsed {
        width: 4% !important;
    }
}


/* Portrait and Landscape */
@media only screen and (min-device-width: 801px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {

    .cta_part h2 {
        font-size: 38px;
        margin-bottom: 0 !important;
    }

    .cta_part button {
        margin-right: 10px;
        width: 40%;
        margin-top: 30px;
        padding: 1% !important;
        font-size: 15px !important;
    }

    marquee.announcement {
        width: 95.5% !important;
        float: right;
        margin-top: 2px !important;
    }

    img.liveSelectionImage {
        height: 170px !important;
    }

    img.liveImage {
        height: 245px !important;
    }

    .winnersLists {
        height: 350px !important;
    }
}

@media only screen and (min-device-width: 600px) and (max-width: 800px) {

    marquee.announcement {
        width: 96.5% !important;
        float: right !important;
        margin-top: 1x !important;
    }

    img.liveImage {
        height: 230px;
    }

    img.liveSelectionImage {
        height: 165px;
    }

    .winnersLists {
        height: 340px !important;
    }
}

@media only screen and (max-width: 600px) {
    .announcement {
        font-size: 12px !important;
        width: 92.5% !important;
        float: right;
        margin-top: 6px !important;
    }

    .liveImage {
        width: 100% !important;
        height: 180px !important;
    }

    .liveSelectionImage {
        height: 140px !important;
        border-radius: 10px !important;
    }

    .winnersLists {
        height: 300px !important;
        min-height: 300px !important;
    }

    .listFooter img {
        float: unset !important;
    }
}


@media only screen and (min-device-width: 1499px) and (max-device-width: 1920px) {
    .ImageSliderDif>div:nth-of-type(1) {
        display: none;
        width: 1160px !important;
        height: 275px !important;
    }

    .ImageSliderDif>div:nth-of-type(1)>div:nth-of-type(1) {
        display: none;
        width: 1160px !important;
        height: 275px !important;
    }

    .ImageSliderDif>.rsis-container {
        display: none;
        width: 1160px !important;
        height: 275px !important;
    }
}

@media only screen and (min-device-width: 1367px) and (max-device-width: 1440px) {
    .ImageSliderDif>div:nth-of-type(1) {
        display: none;
        width: 1155px !important;
        height: 255px !important;
    }

    .ImageSliderDif>div:nth-of-type(1)>div:nth-of-type(1) {
        display: none;
        width: 1155px !important;
        height: 255px !important;
    }

    .ImageSliderDif>.rsis-container {
        display: none;
        width: 1155px !important;
        height: 255px !important;
    }
}

@media only screen and (min-width: 1351px) and (max-device-width: 1366px) {
    .ImageSliderDif>div:nth-of-type(1) {
        display: none;
        width: 1075px !important;
        height: 255px !important;
    }

    .ImageSliderDif>div:nth-of-type(1)>div:nth-of-type(1) {
        display: none;
        width: 1075px !important;
        height: 255px !important;
    }

    .ImageSliderDif>.rsis-container {
        display: none;
        width: 1075px !important;
        height: 255px !important;
    }
}

@media only screen and (min-width: 1201px) and (max-device-width: 1350px) {
    .ImageSliderDif>div:nth-of-type(1) {
        display: none;
        width: 950px !important;
        height: 220px !important;
    }

    .ImageSliderDif>div:nth-of-type(1)>div:nth-of-type(1) {
        display: none;
        width: 950px !important;
        height: 220px !important;
    }

    .ImageSliderDif>.rsis-container {
        display: none;
        width: 950px !important;
        height: 220px !important;
    }
}


@media only screen and (min-width: 1025px) and (max-device-width: 1201px) {
    .ImageSliderDif>div:nth-of-type(1) {
        display: none;
        width: 910px !important;
        height: 220px !important;
    }

    .ImageSliderDif>div:nth-of-type(1)>div:nth-of-type(1) {
        display: none;
        width: 910px !important;
        height: 220px !important;
    }

    .ImageSliderDif>.rsis-container {
        display: none;
        width: 910px !important;
        height: 220px !important;
    }
}

@media only screen and (min-width: 769px) and (max-device-width: 1024px) {
    .ImageSliderDif>div:nth-of-type(1) {
        display: none;
        width: 735px !important;
        height: 180px !important;
    }

    .ImageSliderDif>div:nth-of-type(1)>div:nth-of-type(1) {
        display: none;
        width: 735px !important;
        height: 180px !important;
    }

    .ImageSliderDif>.rsis-container {
        display: none;
        width: 735px !important;
        height: 180px !important;
    }
}

@media only screen and (min-width: 678px) and (max-device-width: 768px) {
    .ImageSliderDif>div:nth-of-type(1) {
        display: none;
        width: 700px !important;
        height: 180px !important;
    }

    .ImageSliderDif>div:nth-of-type(1)>div:nth-of-type(1) {
        display: none;
        width: 700px !important;
        height: 180px !important;
    }

    .ImageSliderDif>.rsis-container {
        display: none;
        width: 700px !important;
        height: 180px !important;
    }
}

// @media only screen and (min-device-width: 320px) and (max-device-width: 568px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {

//     body {
//         display: none !important;
//     }
// }